<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Analyses ></span>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Presentation du tafire
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row my-4">
      <div class="div_left"
           v-if="(user[0] == 'ADMIN' || user[0] == 'DAF' ||user[0] == 'DCG')  && user[1] == 'FER'">
        <a class="btn btn-blue px-5"
           data-toggle="modal"
           ref="modal_button"
           data-target="#exampleModal">Importer données historiques</a>
      </div>
    </div>
    <div class="row justify-content-center af-btn-group">
      <div class="col-2">
        <button
          type="button"
          @click=";(selectedTab = 'analytical-view'), chargeGraph()"
          v-bind:class="{ 'btn btn-block btn-success btn-lg af-btn': selectedTab == 'analytical-view', 'btn btn-block btn-secondary btn-lg af-disabled-btn': selectedTab == 'graphical-view' }"
        >
          Vision Analytique
        </button>
      </div>
      <div class="col-2">
        <button
          type="button"
          @click=";(selectedTab = 'graphical-view'), chargeGraph()"
          v-bind:class="{ 'btn btn-block btn-success btn-lg af-btn': selectedTab == 'graphical-view', 'btn btn-block btn-secondary btn-lg af-disabled-btn': selectedTab == 'analytical-view' }"
        >
          Vision Graphique
        </button>
      </div>
    </div>
    <div class="row justify-content-center pt-3">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="chargeGraph()">
            Rechercher
          </button>
        </div>
      </form>
     
    </div>
    <div v-show="selectedTab == 'analytical-view'">
      <table class="table table-striped text-center mx-auto mt-2"
             style="font-size: 0.6em;">
        <thead>
          <tr class="bg-black">
            <th scope="col">DESIGNATION</th>
            <th scope="col">BUDGET (FCFA) du {{ jour.annee_debut}} au {{ jour.annee_fin }}</th>
            <th scope="col">EXECUTION DU {{ jour.annee_debut}} au {{ jour.annee_fin }}</th>
            <th scope="col">% EXECUTION</th>
          </tr>
        </thead>
        <tbody v-if="tableau!=''">
          <tr v-for="(row, rowkey) in tableau"
              :key="rowkey">
            <td>{{ row.libelle }}</td>  
            <td class="text-right">{{ row.budget.toLocaleString() }}</td>
            <td class="text-right">{{ row.realisation.toLocaleString() }}</td>
            <td class="text-right">{{ row.execution.toLocaleString() }}</td> 
          </tr>
          
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
    <!------------------------------------------------------------------------------------------------------------ 
                                                    vision graphique 
------------------------------------------------------------------------------------------------------------------>
    <div v-show="selectedTab == 'graphical-view'"
         class="pt-2">
      <div class="card">
        <h5 class="card-header af-card-header"></h5>
        <div class="card-body">
          <div class="row">
            <div class="col-10 mx-auto">
              <div class="card">
                <div class="card-header af-card-header">Flux enregistrées du {{ jour.annee_debut}} au {{ jour.annee_fin }}</div>
                <div class="card-body af-card-body">
                  <div style="width: 400px; margin: auto"
                       v-if="loaded">
                    <PieChart :chartdata="randomChart1"
                              :options="optionsChart1" />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-6">
              <div class="card">
                <div class="card-header af-card-header">Charges enregistrées du {{ jour.annee_debut}} au {{ jour.annee_fin }}</div>
                <div class="card-body">
                  <div style="width: 450px; margin: auto"
                       v-if="loaded">
                    <PieChart :chartdata="randomChart2"
                              :options="optionsChart2" />
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Modal relevé bancaire-->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="col-11 mx-auto">
              <div class="card title-card">
                <div class="card-body text-center title">
                  Importer données historiques
                </div>
              </div>
            </div>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="form-group col-md-11">
                  <label for=""
                  >Execution budgétaire</label
                  >
                  <div class="custom-file">
                    <label class="custom-file-label">
                      {{ inputFiles.donnees_histo }}
                    </label>

                    <input type="file"
                           name="etat_comptable"
                           class="custom-file-input"
                           id="donnees_histo"
                           ref="donnees_histo"
                           v-on:change="handleFileUpload('donnees_histo')"
                           accept=".xlsx,.csv" />
                    <span v-if="form.errors().has('donnees_histo')"
                          v-text="form.errors().get('donnees_histo')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-group col-md-1 pt-2 my-auto">
                  <Loader v-show="loading"/>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-f-blue"
                    @click="templateDownload('executionBudgetaireFinal.xlsx')">Télécharger un template</button>
            <button type="button"
                    class="btn btn-f-blue"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-f-blue"
                    :disabled="url == ''"
                    @click="submit">Importer</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PieChart from "@/components/shared/PieChart"
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import Loader from "@/components/shared/Spinner"
import form from "vuejs-form"
import utils from "@/assets/js/utils"

export default {
  name: "Analysebord",
  components: {
    PieChart,
    Loader,
    Notif
  },
  data() {
    return {
      selectedTab: "analytical-view",
      randomChart1: {
        labels: [],
        datasets: [
          {
            label: "Dataset 1",
            data: [],
            backgroundColor: ["#0097A9", "#016677", "#18A0FB", "#4A24C5"]
          }
        ]
      },
      randomChart2: {
        labels: ["PER(décomptes reçus)", "Autres dépenses assimilées", "Dépense de péages", "Dépense de pesages", "Emprunt facilités de trésorerie"],
        datasets: [
          {
            label: "Dataset 1",
            data: [1, 1, 1, 1, 1],
            backgroundColor: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"]
          }
        ]
      },
      optionsChart1: {
        responsive: true
      },
      optionsChart2: {
        responsive: true
      },
      toggler: {
        revenus: 0,
        leveeFond: 0,
        charges: 0
      },
      notif: {
        activated: "",
        type: "",
        message: ""
      },
      listExtensionFiles:"",
      url:"",
      uploadfile:"",
      loaded: false,
      loading:false,
      inputFiles: {  
        donnees_histo:"" 
      },
      form: form({
        donnees_histo:"" 
      })
        .rules({
          donnees_histo:"required" 
        })
        .messages({
          donnees_histo:"Ce champ est requis" 
        }),
      tableau:"",
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      year: {
        annee_debut: new Date().getFullYear()+"-01-01",
        annee_fin: new Date().getFullYear()+"-12-31",
      },
      jour: {
        annee_debut: "01-01-"+new Date().getFullYear(),
        annee_fin: "12-31-"+new Date().getFullYear(),
      }
    }
  },
  created() {
    this.loadDashboardTable(this.year)
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.listExtensionFiles=utils.formaFichierAccepte
  },
  watch: {
    tBoard() {
      if (this.tBoard) {
        this.tableau = []
        this.loaded = true
        for (let index = 0; index < this.tBoard.designation.length; index++) {
          this.tableau.push({
            libelle:this.tBoard.designation[index],
            realisation:this.tBoard.realisation[index],
            budget:this.tBoard.budget[index],
            execution: this.tBoard.budget[index] != 0 ? Math.round((this.tBoard.realisation[index]*100)/this.tBoard.budget[index]):0
          })
        } 
        // this.tableau = this.tBoard
        this.setTBoard("")
        console.log(this.tableau)
        if (this.tableau.length>0) {
          var count = 0
          this.tableau.forEach(element => {
            this.randomChart1.datasets[0].data[count] = Math.round(element.realisation)
            this.randomChart1.labels[count]=element.libelle+"("+ this.randomChart1.datasets[0].data[count].toLocaleString() +")"
            count++
          })
        }
        //   if (this.tableau[0].VG_RESSOURCES_ENREGISTREES.length !== 0) {
        //     this.randomChart1.datasets[0].data[0] = Math.round(Number(this.tableau[0].VG_RESSOURCES_ENREGISTREES[0].  TSU) * 100) / 100
        //     this.randomChart1.labels[0]="TSU,patentes et vignettes("+ this.randomChart1.datasets[0].data[0] +"%)"

        //     this.randomChart1.datasets[0].data[1] = Math.round(Number(this.tableau[0].VG_RESSOURCES_ENREGISTREES[0].  PESAGE) * 100) / 100
        //     this.randomChart1.labels[1]="Pesages("+ this.randomChart1.datasets[0].data[1] +"%)"

        //     this.randomChart1.datasets[0].data[2] = Math.round(Number(this.tableau[0].VG_RESSOURCES_ENREGISTREES[0].  PEAGE) * 100) / 100
        //     this.randomChart1.labels[2]="Péages("+ this.randomChart1.datasets[0].data[2] +"%)"

        //   } else {
        //     this.randomChart1.datasets[0].data = []
        //     this.randomChart1.datasets[0].backgroundColor = []
        //     this.randomChart1.labels = []
        //   }

        //   // chart 2
        //   if (this.tableau[0].VG_CHARGES_ENREGISTREES.length !== 0) {
        //     this.randomChart2.datasets[0].data[0] = Math.round(Number(this.tableau[0].VG_CHARGES_ENREGISTREES[0]. DECOMPTES_RECUS) * 100) / 100
        //     this.randomChart2.labels[0]="PER(décomptes reçus)("+ this.randomChart2.datasets[0].data[0] +"%)"

        //     this.randomChart2.datasets[0].data[1] = Math.round(Number(this.tableau[0].VG_CHARGES_ENREGISTREES[0]. AUTRES_DEPENSES_ASSIMILEES) * 100) / 100
        //     this.randomChart2.labels[1]="Autres dépenses assimilées("+ this.randomChart2.datasets[0].data[1] +"%)"

        //     this.randomChart2.datasets[0].data[2] = Math.round(Number(this.tableau[0].VG_CHARGES_ENREGISTREES[0]. DEPENSES_PEAGES) * 100) / 100
        //     this.randomChart2.labels[2]="Dépense péages("+ this.randomChart2.datasets[0].data[2] +"%)"

        //     this.randomChart2.datasets[0].data[3] = Math.round(Number(this.tableau[0].VG_CHARGES_ENREGISTREES[0]. DEPENSES_PESAGES) * 100) / 100
        //     this.randomChart2.labels[3]="Dépense pesages("+ this.randomChart2.datasets[0].data[3] +"%)"
            
        //     this.randomChart2.datasets[0].data[4] = Math.round(Number(this.tableau[0].VG_CHARGES_ENREGISTREES[0]. EMPRUNTS_FACILITES) * 100) / 100
        //     this.randomChart2.labels[4]="Emprunt facilités de trésorerie("+ this.randomChart2.datasets[0].data[4] +"%)"
            

        //   } else {
        //     this.randomChart2.datasets[0].data = []
        //     this.randomChart2.datasets[0].backgroundColor = []
        //     this.randomChart2.labels = []
        //   }
        // }else{
        //   this.randomChart1.datasets[0].data = []
        //   // this.randomChart1.datasets[0].backgroundColor = []
        //   // this.randomChart1.labels = []
        //   this.randomChart2.datasets[0].data = []
        //   // this.randomChart2.datasets[0].backgroundColor = []
        //   // this.randomChart2.labels = []
        // }
      }
    },
    msgSuccessExecution(){
      if (this.msgSuccessExecution) {
        this.notif.message = this.msgSuccessExecution
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setFiles("")
            this.setMsgSuccessExecution("")
            this.setMsgFailExecution("")
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
            this.uploadfile=""
          }.bind(this),
          3000
        )
      }
    },
    msgFailExecution(){
      if (this.msgFailExecution) {
        this.notif.message = this.msgFailExecution
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.setFiles("")
        this.loading=false
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs["donnees_histo"].value=""
            this.inputFiles["donnees_histo"]=""
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.url = this.form[this.uploadfile]
        this.loading=false
      }
    },
    errors(){
      if (this.errors) {
        this.notif.message = this.errors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.loading=false
        setTimeout(
          function() {
            this.setFiles("")
            this.setMsgSuccessExecution("")
            this.setMsgFailExecution("")
            this.form[this.uploadfile]=""
            this.inputFiles.donnees_histo=""
            this.$refs["donnees_histo"].value=""
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
  },
  
  computed: {
    ...mapGetters(["tBoard", "cBoard","msgSuccessExecution","msgFailExecution","files","errors"])
  },
  methods: {
    ...mapActions(["loadDashboardTable", "loadDashboardChart","importExecutionBudgetaire","saveFile"]),
    ...mapMutations(["setMsgSuccessExecution", "setMsgFailExecution","setFiles","setErors","setTBoard"]),
    chargeGraph() {
      this.loaded = false
      this.year.annee_debut = this.annee_debut
      this.year.annee_fin = this.annee_fin
      this.jour.annee_debut = this.annee_debut.split("-")
      this.jour.annee_fin = this.annee_fin.split("-")
      this.jour.annee_debut = this.jour.annee_debut[2]+"-"+this.jour.annee_debut[1]+"-"+this.jour.annee_debut[0]
      this.jour.annee_fin = this.jour.annee_fin[2]+"-"+this.jour.annee_fin[1]+"-"+this.jour.annee_fin[0]
      this.loadDashboardTable(this.year)
    },
    handleFileUpload(filename) {
      if (this.$refs[filename].files[0]) {
        this.inputFiles[filename] = this.$refs[filename].files[0].name
        if (!this.inputFiles[filename].includes(" ")) {
          this.loading=true
          var extension = this.inputFiles[filename].split(".")[1]
          if (this.listExtensionFiles.includes(extension)) {
            this.uploadfile = filename
            this.saveFile(this.$refs[filename].files[0])
          } else {
            this.$refs[filename].disabled = false
            this.inputFiles[filename] = ""
            this.loading=false
            this.notif.message = "Mauvais Format du fichier"
            this.notif.type = "toast-danger"
            this.notif.activated = "activated"
            setTimeout(
              function() {
                this.notif.activated = ""
                this.setErors("")
              }.bind(this),
              5000
            )
          }  
        }else{
          this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          this.loading = false

          setTimeout(
            function() {
              this.notif.activated = ""
              this.$refs[filename].value=""
              this.inputFiles[filename]=""
            }.bind(this),
            3000
          )
        }
      }
    },
    clickToggling(key) {
      if (this.toggler[key] == 0) {
        this.toggler[key] = 1
      } else {
        this.toggler[key] = 0
      }
    },
    submit() {
      this.importExecutionBudgetaire({url:this.url})
    },
    templateDownload(file){
      const link = document.createElement('a')
      link.href = process.env.VUE_APP_APIURL+"files/excel/FichiersModel/"+file
      link.download = file
      link.click()
      URL.revokeObjectURL(link.href)
    },
  }
}
</script>
<style>
@import "./operationsBancaires.css";
</style>

<style scoped>
.btn:focus {
  outline: none !important;
}

.btn:focus {
  box-shadow: none;
}

.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #0097a9;
  text-transform: uppercase;
}

.title-card {
  background: rgba(229, 229, 229, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.af-btn {
  background: #8cc63e;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6em;
  color: #016677;
}

.af-btn-group {
  padding-top: 20px;
}

.af-disabled-btn {
  background: rgba(229, 229, 229, 0.5);
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 0.6em;
  color: rgba(128, 128, 128, 0.5);
}

.af-card-header {
  background: #0097a9;
  height: 32px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 0.7em;
  line-height: 0.9em;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.af-bg {
  background: #c4c4c4;
}
form.form-inline input,
/* form.form-inline label, */
form.form-inline button{
  font-size: 0.6em;
}
form.form-inline input{
  height: fit-content;
}
</style>
